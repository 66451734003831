
import { JSEncrypt } from 'jsencrypt'

const privateKey = `-----BEGIN PRIVATE KEY-----
MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDFJxVwpfq9+ftx
pssTGcuSoeyW9YyNxJS6Y3IaZGH/5PY5/zHVuUMt+4kXts00kFMKMOwf8Z9WKTZD
8rrNR+m/x/COQEcu6by8Ke0mPNgtAllN16lToW1DPu2NMZA8y+EDBviJxKrzCAsY
Nwi2GsSfMgnrUqa9ECKEQoeNxApZd0HT8CgmxKxDxWLAWWn4NYlA8Qr8kW5OOnzL
udglzOY7VUt5uquzQurcB0yfS0FH1QV3fWEk9lSyI9zCUunrc93ABLzJJTUuNGX4
Ja/hL2OQfNLdfiHghaRbuKNCkB6fn0Rq/nCiDRPffLm5VbuT9Plg3DLWhpi/p+8R
R7W2lIkzAgMBAAECggEABFwyh8zb6+2NchjYgpA+UGAJ1NgvCofTK1YaBr8QXccl
lcv/FoqOodj74D7iqXnhpENVuDGUoJY+aJm8fVHa/tWHU3mb5BL+RSybiFU8CZJY
cu6MIPVqfM4R3IldPfvbM5ZwC7Rl0egIsB0FP8zg+VSPse51vFZYLfdpJX7DXdUC
l7UNWDP/3GpwDN17ZUk3ZWDlHmVVKnGtpp27kBWapo/lw+KDYsUiPyPm0iV+N532
4PuYj3O/DAwXmPbU38mYonAwkFK4O0X3dlsb+Be9MBRZjfjurpNrH7dd2xmT6hom
dWs+q9b6VWc82Dw/4Zgw3zdm5JKhLiHtTYtPf0xkBQKBgQDiAFPFhQ2sjMqLlS5x
HcArlZLM4DTebE7w8qJxhhmFRrOPwfhF7WgA8u1IyD0bH+hzyb91iEQv/yYsw9J5
HwvrCiiD8GHwPZ+NjHuE7hUcQR899rqPio77c1tQBRRgfBVRAtynw7uzuZtcoGAy
5rX6osgFYGRHMXapPc4GqNm6TwKBgQDfUnY3Dt4wUlGg+q2jogk6jZgvsZ6H5S+P
XiaWq0UIwCLHhYJVI70Ah3hIB2U4atVVaNAk/MuHUo7UONSQFt533F3JbDwcdtTU
rI/rrojxtTQZkbsG7V+PYYqlfrgd7T6M1Why2Ks/Z6Gs8nUlNHNSVR1QO1pSwV2e
CUv9OxBd3QKBgQCSfYPPVB846UmgkGI6D/6wQZOWGP4r7ih9JckFrNZ9/QYG+MPl
D62Kb+w+cQyquFQEOZFQrbMFKW2lNokG2YjwIPWCEB5rioYRSi4GTAyC8/M8RuFG
iZmEg5F8nM5IfCWVDTXZyJWyOWzq7UlttP3gKANe+MwHGZjAur8AshHvIQKBgEcd
tXOXEn6E2GXRLlZ7lUDGMn6Nq5RPEbQwIU29wMAmn2y2QWjndpqiiVU2ta8fpkwK
kq4UqqOvULiYWGiCP9ofnOY1x3axCNmpEjPVYtrF/49c7xgno1S/lS1aiztMf6zN
kPNxuCb602uF0nREnazMWgdgv0S3Ox7GiyJbGr0pAoGBAM/tpB153YFQ9Aye9AJx
cKXoagBVxbjLi0wCngwOOiYQTwZc3vqzv0oymkUBLRRnXrYdrw3dQkU02EPb5LGE
TMPxXFQcn5/o0sSiaajBwOPIh+I1pnYqj0so6ujLp+1UdOjtcb7oEVxJlMXQ4eCY
iXJ1+zSE/ph9CvbhL3qlaHRo
-----END PRIVATE KEY-----
`;

export function decryptedData (msg:any) {
  const decrypt = new JSEncrypt()
  decrypt.setPrivateKey( `-----BEGIN RSA PRIVATE KEY-----${privateKey}-----END RSA PRIVATE KEY-----`)
  const decryptMsg = decrypt.decrypt(msg)
  return decryptMsg
}

