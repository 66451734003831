import axios from 'axios'
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'


const http: AxiosInstance = axios.create({
    timeout: 30 * 1000
})




const request = <T = unknown>(config: AxiosRequestConfig): Promise<T> => {
    return new Promise((resolve, reject) => {
        http
            .request<T>(config)
            .then((res: AxiosResponse) => resolve(res.data))
            .catch((err: { msg: string }) => reject(err))
    })
}

export const get = <T = any>(url: string, params?: object, config?: AxiosRequestConfig): Promise<T> => {
    return request({
        method: 'get',
        url,
        params,
        ...config
    })
}

const post = <T = any>(url: string, params?: object, config?: AxiosRequestConfig): Promise<T> => {
    return request({
        method: 'post',
        url,
        data: params,
        ...config
    })
}


export default { get, post }