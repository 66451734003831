import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = ["href"]

import {getEncryptedData} from '@/api/common'
import {decryptedData} from '@/utils/encrypt'
import {ref} from "vue";
import {type WebInfo} from '@/api/type'


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {


const dataSource = ref<WebInfo>({
  site_name: '',
  site_url: '',
  site_service_email: ''
})

const getData = async () => {
  const result = await getEncryptedData()
  try {
    const jsonStr = decryptedData(result)
    const data = JSON.parse(jsonStr)
    Object.assign(dataSource.value, data)
  } catch (error) {
    console.log("error", error)
  }
}
getData()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(dataSource.value.site_name) + "最新地址发布页", 1),
      _createElementVNode("a", {
        class: "button",
        href: dataSource.value.site_url,
        target: "_blank"
      }, "网址：" + _toDisplayString(dataSource.value.site_url) + " 点击进入", 9, _hoisted_3),
      _createElementVNode("h1", null, [
        _cache[0] || (_cache[0] = _createTextVNode("客服邮箱：")),
        _createElementVNode("span", null, _toDisplayString(dataSource.value.site_service_email), 1)
      ])
    ])
  ]))
}
}

})